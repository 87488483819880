// Variables
$black: #000000;
// Restated Bootstrap Variables
$gray-100: #171717 !default;
$gray-200: #f6f8f8 !default;
$gray-300: #f6f8f8 !default;
$gray-400: #6b6861 !default;
$gray-500: #2f3138 !default;
$gray-600: #40434d !default;
$gray-700: #585b69 !default;
$gray-800: #343a40 !default;
$gray-900: #4b4b56 !default;
// $primary: #878787 !default;
