// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
  color: #f5f5f3;
  background-color: $black;
}

p {
  line-height: 1.75;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

.text-muted {
  color: $gray-200 !important;
  font-weight: 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

.page-section {
  background-color: $black !important;
  padding: 100px 0;
  h2.section-heading {
    color: $white;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    // font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}
@media (min-width: 768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

.black-and-white {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.black-and-white:hover {
  filter: none;
}

.plyr {
  --plyr-color-main: #ffffff;
  --plyr-audio-control-color: #fff;
  --plyr-audio-control-color-hover: $black !important;
  // --plyr-audio-control-background-hover:;
  // --plyr-menu-background:;
  --plyr-audio-controls-background: $black;
}

// #about {
//   background-color: #121212 !important;
// }

.about-img {
  display: flex;
  justify-content: center !important;
  margin-bottom: 20px !important;
}
